<template>
	<div :class="themeClass" class="contBox margin-t-10 bg-fff" v-loading="pageLoading" >
		<div class="newPersonbtn-title font-size18 font-weight700 margin-t-4 padding-b-13 font-color-000">我的车辆</div>
		<div class="newPersonbtn-Box">
			<el-form :model="addCarForm" :rules="rules" ref="addCarForm" label-width="130px" class="demo-ruleForm">
				<div class="text-center font-size16 margin-b-25">{{pageTitle}}</div>
				<el-form-item label="是否启用">
					<el-switch  v-model="addCarForm.fStatus" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
				</el-form-item>
				<el-form-item label="分组编码" prop='fPlateNumber'>
					<el-input v-model.trim="addCarForm.fPlateNumber" placeholder="请输入分组编码"></el-input>
				</el-form-item>
				<el-form-item label="车辆分组" prop='fLinkMan'>
					<el-input v-model="addCarForm.fLinkMan" placeholder="请输入车辆分组"></el-input>
				</el-form-item>
				<el-form-item label="备注">
					<el-input v-model="addCarForm.fRemark" placeholder="请输入备注"></el-input>
				</el-form-item>
				<el-form-item>
					<div class="comBtn font-color-fff bg-theme text-center border-theme radius2" @click="submitForm('addCarForm')">保存</div>
				</el-form-item>
			</el-form>
			<div class="bg-box"></div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		data() {
			var checkMaximum = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('重量不能为空'));
				}
				setTimeout(() => {
					if (value < 1) {
						callback(new Error('重量必须大于0'));
					} else {
						callback();
					}

				}, 1000);
			};
			return {
				pageLoading: false,
				pageTitle: "新增车辆信息",
				carToolList: [],
				addCarForm: {
					fStatus:true,//分组是否启用的状态
					fPlateNumber: '', //分组编码
					fLinkMan: '', //车辆分组
					fRemark:'',//备注
				},
				rules: {
					fPlateNumber: [{
						required: true,
						message: '请输入分组编码',
						trigger: 'change'
					}],
					fLinkMan: [{
						required: true,
						message: '请输入车辆分组',
						trigger: 'change'
					}],
				},
				backEditList: {},
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		components: {},
		created() {
			this.pageTitle = this.$route.query.pageType;
			if (this.pageTitle == "编辑车辆分组") {
				this.getCarEdit();
				console.log(this.$route.query.carToolID + "车辆id");

			}
		},
		methods: {
			//通过分组的id获取要修改的分组数据
			getCarEdit() {
				this.ApiRequestPost('api/mall/ebcustomer/transport-tool-group/get', {
					id: this.$route.query.carToolID
				}).then(
					result => {
						this.backEditList = result.obj;
						//回显数据
						this.addCarForm.fPlateNumber = this.backEditList.fGroupCode
						this.addCarForm.fLinkMan = this.backEditList.fGroup
						this.addCarForm.fRemark = this.backEditList.fRemark
						if(this.backEditList.fStatus == 1){
							this.addCarForm.fStatus = true
						}else{
							this.addCarForm.fStatus = false
						}
					},
					rej => {}
				);
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.pageTitle == "编辑车辆分组") {
							this.pageLoading = true;
							this.ApiRequestPut('api/mall/ebcustomer/transport-tool-group/update', {
								  fStatus: this.addCarForm.fStatus == true ?1:0,
								  fGroupID: this.backEditList.fGroupID,
								  fGroupCode:this.addCarForm.fPlateNumber,
								  fGroup: this.addCarForm.fLinkMan,
								  fRemark:  this.addCarForm.fRemark,
								  frv: this.backEditList.frv,
							}).then(
								result => {
									this.pageLoading = false;
									this.$router.push({
										path: '/PersonalContent/MyVehicle',
										query: '',
									});

								},
								rej => {
									this.pageLoading = false;
								}
							);

						} else if (this.pageTitle == "新增车辆分组") {
							this.pageLoading = true;
							this.ApiRequestPost('/api/mall/ebcustomer/transport-tool-group/create', {
								fGroupCode: this.addCarForm.fPlateNumber,
								fGroup: this.addCarForm.fLinkMan,
								fStatus: this.addCarForm.fStatus == true ? 1:0,
								fRemark:this.addCarForm.fRemark,
							}).then(
								result => {
									this.pageLoading = false;
									this.$router.push({
										path: '/PersonalContent/MyVehicle',
										query: '',
									});

								},
								rej => {
									this.pageLoading = false;
								}
							);

						}


					} else {
						return false;
					}
				});
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.bg-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.contBox {
		width: 100%;
	}

	.demo-ruleForm {
		width: 462px;
		margin: 0px auto;
		position: relative;
		z-index: 1;
	}

	.newPersonbtn-Box {}

	.bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 25%;
	}

	.newPersonbtn-title {
		width: 73px;
		height: 22px;
		padding: 8px 14px;
	}

	.el-button {
		width: 160px;
		height: 40px;
	}

	::v-deep .el-input__inner,
	.el-select {
		width: 100%;
		height: 32px;
		line-height: 32px;
	}

	/* 按钮 */
	.comBtn {
		width: 160px;
		height: 38px;
		line-height: 38px;
		cursor: pointer;
		display: inline-block;
		margin-top: 20px;
	}
</style>
